import React, { useEffect } from 'react';
import Tetris from './Tetris'; // Import the Tetris component

// CSS-in-JS styles for the Hero component
const injectStyles = () => {
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerHTML = `
    #hero-container {
      position: relative;
      width: 100%;
      height: 100vh; /* Full viewport height */
      overflow: hidden;
      background: linear-gradient(135deg, #1e3c72 0%, #1e6091 50%, #2a5298 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: white;
      font-family: 'Arial', sans-serif;
      padding: 20px; /* Padding for mobile */
      box-sizing: border-box; /* Include padding in width/height */
      animation: backgroundFade 10s infinite alternate; /* Background animation */
    }

    @keyframes backgroundFade {
      0% { background: linear-gradient(135deg, #1e3c72 0%, #1e6091 50%, #2a5298 100%); }
      100% { background: linear-gradient(135deg, #2a5298 0%, #1e6091 50%, #1e3c72 100%); }
    }

    .hero-content {
      position: absolute;
      z-index: 10;
      max-width: 600px; /* Responsive max width */
      padding: 30px;
      background: rgba(34, 34, 34, 0.85); /* Darker semi-transparent background */
      border-radius: 15px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
      text-align: left; /* Align text to the left to mimic code */
      font-family: 'Courier New', monospace; /* Code font style */
      animation: fadeIn 1s ease-in-out, slideUp 0.5s ease-in-out;
      opacity: 0;
      transform: translateY(20px);
      animation-fill-mode: forwards;
    }

    .hero-content h1 {
      font-size: 2.2rem; /* Larger code font size */
      margin: 0 0 10px 0;
      font-family: 'Courier New', monospace; /* Code font style */
      animation: slideInLeft 0.5s ease-out;
    }

    .hero-content p {
      font-size: 1rem; /* Responsive font size */
      margin: 10px 0 20px 0;
      line-height: 1.5; /* Better readability */
      font-family: 'Courier New', monospace; /* Code font style */
      animation: slideInLeft 0.5s ease-out;
      animation-delay: 0.3s;
      animation-fill-mode: both;
    }

    .hero-content code {
      background: #272822; /* Darker background for code blocks */
      padding: 10px 15px;
      border-radius: 8px;
      display: block;
      color: #66d9ef; /* Highlighted code color */
      margin: 10px 0;
      font-size: 1.1rem;
      animation: slideInLeft 0.5s ease-out;
      animation-delay: 0.6s;
      animation-fill-mode: both;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    .hero-content button {
      padding: 14px 28px;
      font-size: 1.1rem;
      color: #fff;
      background: linear-gradient(45deg, #ff6b81, #ff4757); /* Gradient background */
      border: none;
      border-radius: 30px; /* More rounded corners */
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Stronger shadow */
      cursor: pointer;
      transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
      margin: 0 10px;
      flex: 1;
      animation: bounceIn 1s ease;
      animation-delay: 1s;
      animation-fill-mode: both;
    }

    .hero-content button:hover {
      background: linear-gradient(45deg, #ff4757, #ff6b81); /* Reverse gradient */
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Slightly larger shadow */
      transform: translateY(-4px); /* Lift effect */
    }

    .hero-content button:active {
      transform: translateY(0); /* Button pressed effect */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    }

    #tetris-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 5; /* Ensure Tetris is below hero content */
      animation: fadeIn 2s ease-in-out; /* Fade-in for Tetris */
    }

    /* Fade-in animation */
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }

    /* Slide-up animation */
    @keyframes slideUp {
      from { transform: translateY(20px); }
      to { transform: translateY(0); }
    }

    /* Slide-in-left animation */
    @keyframes slideInLeft {
      from { transform: translateX(-20px); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    /* Bounce-in animation for the button */
    @keyframes bounceIn {
      0% { transform: scale(0.5); opacity: 0; }
      60% { transform: scale(1.2); opacity: 1; }
      100% { transform: scale(1); }
    }

    /* Media queries for mobile responsiveness */
    @media (max-width: 768px) {
      .hero-content {
        max-width: 90%;
        padding: 20px;
      }
      .hero-content h1 {
        font-size: 1.8rem; /* Smaller heading size for tablets and mobiles */
      }
      .hero-content p {
        font-size: 0.9rem;
      }
      .button-container {
        flex-direction: column;
      }
      .hero-content button {
        padding: 12px 24px;
        font-size: 1rem;
        margin: 10px 0; /* Add margin for spacing in column layout */
      }
    }

    @media (max-width: 480px) {
      .hero-content {
        max-width: 90%;
        padding: 15px;
      }
      .hero-content h1 {
        font-size: 1.5rem; /* Even smaller for small mobiles */
      }
      .hero-content p {
        font-size: 0.85rem;
      }
      .hero-content button {
        padding: 10px 20px;
        font-size: 0.9rem;
      }
    }
  `;
  document.head.appendChild(styleSheet);
};

const Hero = ({ onScrollToPlans }) => {
  // Inject styles when the component is mounted
  useEffect(() => {
    injectStyles();
  }, []);

  const handleRequestIntegration = () => {
    window.location.href = 'https://forms.gle/uutJ2bAbVagKbzHt5'; // Replace with your actual HTTP link
  };

  return (
    <div id="hero-container">
      <div className="hero-content">
        <h1>Play Unite Integration</h1>
        <p>
          Supercharge your game's potential. No SDK hassle.<br />
          <code>npm install play-unite-sdk</code>
        </p>
        <div className="button-container">
          <button onClick={handleRequestIntegration}>Request Integration</button>
          <button onClick={onScrollToPlans}>Calculate Revenue Increase</button>
        </div>
      </div>
      <div id="tetris-container">
        <Tetris />
      </div>
    </div>
  );
};

export default Hero;
