import React from 'react';
import { analytics, logEvent } from './Firebase';
import { FaCogs, FaChartLine, FaServer } from 'react-icons/fa'; // Example icons

const Developers = () => {
  const handleClick = (featureName) => {
    logEvent(analytics, 'developer_feature_click', { feature: featureName });
  };

  const developerFeatures = [
    { title: 'SDK-less Integration', description: 'Quickly integrate without adding bulk.', icon: <FaCogs className="text-blue-500 w-12 h-12" />, action: () => handleClick('SDK-less Integration') },
    { title: 'Detailed Analytics', description: 'Track user behavior and game success.', icon: <FaChartLine className="text-green-500 w-12 h-12" />, action: () => handleClick('Analytics') },
    { title: 'Scalable Infrastructure', description: 'Grow without worrying about scaling issues.', icon: <FaServer className="text-purple-500 w-12 h-12" />, action: () => handleClick('Scalability') },
  ];

  return (
    <section className="bg-gradient-to-b from-gray-100 to-gray-300 py-20 px-10 relative">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">Built for Developers</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {developerFeatures.map((feature, index) => (
            <button
              key={index}
              onClick={feature.action}
              className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300"
              aria-label={`Feature: ${feature.title}`}
            >
              {feature.icon}
              <h3 className="text-xl font-semibold mb-2 mt-4">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Developers;
