import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Function to update the title and favicon
function updateMeta() {
  document.title = 'Play Unite'; // Update the title

  // Update the favicon with a static path
  const favicon = document.createElement('link');
  favicon.rel = 'icon';
  favicon.href = '../assets/app_icon.png';
  document.head.appendChild(favicon);
}

// Create the root and render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Call the updateMeta function to set title and favicon
updateMeta();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
