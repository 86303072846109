import React, { useState } from 'react';
import { analytics, logEvent } from './Firebase';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
    logEvent(analytics, 'modal_open', { content_type: content });
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent('');
    logEvent(analytics, 'modal_close', { content_type: modalContent });
  };

  return (
    <>
      <footer className="bg-gray-800 py-12 text-white text-center">
        <div className="container mx-auto">
          <p className="mb-4">&copy; 2024 Play Unite. All rights reserved.</p>
        </div>
      </footer>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">{modalContent === 'privacy' ? 'Privacy Policy' : 'Terms of Service'}</h2>
            <p className="text-gray-600">Content for {modalContent} goes here...</p>
            <button onClick={closeModal} className="mt-4 bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
