import React from 'react';
import Hero from './Hero'; // Hero section component
import Features from './Features'; // Features section component
import Developers from './Developers'; // Developers-focused section
import Plans from './Plans'; // Pricing or plans section component
import Footer from './Footer'; // Footer section component

const DeveloperLandingPage = () => {
  // Handlers for navigation
  const scrollToPlans = () => {
    document.getElementById('plans-section').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      {/* Hero section */}
      <Hero onScrollToPlans={scrollToPlans} />

      {/* Features section */}
      <Features />

      {/* Developers section */}
      <Developers />

      {/* Plans section */}
      <section id="plans-section">
        <Plans />
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DeveloperLandingPage;
