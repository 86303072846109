import React, { useEffect, useRef, useState } from 'react';
import { analytics, logEvent } from './Firebase';

const Hero = ({ scrollToPlans, scrollToGames }) => {
  const containerRef = useRef(null);
  const backgroundElementRefs = useRef([]);
  const textRefs = useRef([]);
  const [visibleTextIndices, setVisibleTextIndices] = useState([]);

  const handleScrollToPlans = () => {
    logEvent(analytics, 'scroll_to_plans_clicked');
    scrollToPlans();
  };

  const handleScrollToGames = () => {
    logEvent(analytics, 'scroll_to_games_clicked');
    scrollToGames();
  };

  const getRandomOrbSize = () => {
    const minSize = 100;
    const maxSize = 300;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const baseSize = (screenWidth + screenHeight) / 20;
    const sizeVariation = Math.random() * baseSize * 0.5;
    const size = baseSize + sizeVariation;

    return Math.max(minSize, Math.min(size, maxSize));
  };

  const animateOrb = (element) => {
    if (!element || !containerRef.current) return;

    const size = getRandomOrbSize();
    element.style.width = `${size}px`;
    element.style.height = `${size}px`;

    const containerRect = containerRef.current.getBoundingClientRect();
    let positionX = Math.random() * (containerRect.width - size);
    let positionY = Math.random() * (containerRect.height - size);
    let velocityX = (1 + Math.random() * 3) * (Math.random() < 0.5 ? 1 : -1);
    let velocityY = (1 + Math.random() * 3) * (Math.random() < 0.5 ? 1 : -1);

    const moveElement = () => {
      positionX += velocityX;
      positionY += velocityY;

      if (positionX + size >= containerRect.width || positionX <= 0) {
        velocityX *= -1;
      }

      if (positionY + size >= containerRect.height || positionY <= 0) {
        velocityY *= -1;
      }

      element.style.transform = `translate(${positionX}px, ${positionY}px) rotate(${positionX}deg) scale(1.1)`;
      requestAnimationFrame(moveElement);
    };

    moveElement();
  };

  useEffect(() => {
    const containerElement = containerRef.current;

    if (containerElement) {
      backgroundElementRefs.current.forEach((element) => animateOrb(element));
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = textRefs.current.indexOf(entry.target);
          if (entry.isIntersecting) {
            setVisibleTextIndices((prevIndices) => [...new Set([...prevIndices, index])]);
          } else {
            setVisibleTextIndices((prevIndices) => prevIndices.filter((i) => i !== index));
          }
        });
      },
      { threshold: 0.1 }
    );

    textRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => {
      textRefs.current.forEach((ref) => ref && observer.unobserve(ref));
    };
  }, []);

  const textLines = [
    "Unlimited access to 8+ incredibly fun games.",
    "New game added every month",
    "Play across your mobile devices.",
    "No intrusive ads.",
  ];

  return (
    <>
      <header
        ref={containerRef}
        className="relative bg-white px-10 text-black overflow-hidden min-h-screen flex items-center justify-center"
      >
        <div className="absolute inset-0 opacity-100"></div>
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-7xl font-extrabold mb-6 tracking-tight gradient-text drop-shadow-2xl">
            Play Unite
          </h1>
          <p className="text-4xl max-w-2xl mx-auto mb-8 leading-relaxed text-black drop-shadow-md">
            Say goodbye to ads and hello to uninterrupted fun!
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <button
              onClick={handleScrollToPlans}
              className="relative bg-gradient-to-r from-orange-500 to-orange-700 text-white px-8 py-4 md:px-10 md:py-4 rounded-full font-bold text-2xl shadow-xl transition-transform hover:scale-110 hover:rotate-1 overflow-hidden hover:shadow-2xl"
            >
              <span className="relative z-10">Play Games Ad-Free</span>
              <span className="shine-effect"></span>
            </button>
            <button
              onClick={handleScrollToGames}
              className="bg-orange-600 text-white border border-orange-600 px-8 py-4 md:px-10 md:py-4 rounded-full font-bold text-2xl shadow-md transition-transform hover:scale-110 hover:-rotate-1 hover:shadow-lg"
            >
              Discover Games
            </button>
          </div>
        </div>
        <div
          ref={(el) => (backgroundElementRefs.current[0] = el)}
          className="absolute bg-gradient-to-r from-pink-500 to-yellow-500 rounded-full filter blur-lg opacity-50"
          style={{ top: 0, left: 0 }}
        ></div>
        <div
          ref={(el) => (backgroundElementRefs.current[1] = el)}
          className="absolute bg-gradient-to-r from-green-400 to-blue-400 rounded-full filter blur-lg opacity-50"
          style={{ top: 100, left: 200 }}
        ></div>
        <div
          ref={(el) => (backgroundElementRefs.current[2] = el)}
          className="absolute bg-gradient-to-r from-purple-500 to-red-500 rounded-full filter blur-lg opacity-50"
          style={{ top: 200, left: 400 }}
        ></div>
      </header>

      <section className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300 py-20 px-10 relative flex items-center justify-center">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-7xl font-extrabold mb-6 tracking-tight text-black drop-shadow-2xl">
            Why Play Unite?
          </h1>
          <h2 className="text-5xl font-extrabold text-black-600 drop-shadow-md">
            {textLines.map((line, index) => (
              <span
                key={index}
                ref={(el) => (textRefs.current[index] = el)}
                className={`block transition-transform duration-700 ease-out transform py-8 mt-8 mb-8 ${
                  visibleTextIndices.includes(index)
                    ? 'opacity-100 fade-in translate-y-0'
                    : 'opacity-0 translate-y-10'
                } text-line`}
                style={{ transitionDelay: `${index * 0.2}s` }}
              >
                {line.split(' ').map((word, i) => {
                  const highlightedWords = [
                    'access',
                    '8+',
                    'added',
                    'game',
                    'across',
                    'month',
                    'devices',
                    'ads',
                    'No',
                    'games',
                    'interruptions',
                    'remove',
                  ];
                  const isHighlighted = highlightedWords.includes(word.replace('.', ''));
                  return (
                    <span
                      key={i}
                      className={`${
                        isHighlighted
                          ? 'highlighted text-orange-500 hover:text-orange-700'
                          : ''
                      }`}
                    >
                      {word}
                    </span>
                  );
                }).reduce((prev, curr) => [prev, ' ', curr])}
              </span>
            ))}
          </h2>
        </div>
      </section>

      <style jsx>{`
        .gradient-text {
          background: linear-gradient(270deg, #ff8c00, #ff5f6d, #42a5f5, #7e57c2, #ff8c00);
          background-size: 400% 400%;
          -webkit-background-clip: text;
          color: transparent;
          animation: gradientShift 8s ease infinite;
        }

        @keyframes gradientShift {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .shine-effect {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            120deg,
            transparent,
            rgba(255, 255, 255, 0.6) 50%,
            transparent
          );
          opacity: 0.8;
          transform: translateX(-150%);
          animation: shine 1.5s infinite ease-in-out;
        }

        @keyframes shine {
          0% {
            transform: translateX(-150%);
          }
          50% {
            transform: translateX(150%);
          }
          100% {
            transform: translateX(150%);
          }
        }

        .fade-in {
          animation: fadeIn 1.2s ease-out forwards;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px) scale(0.95);
          }
          100% {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }

        .text-line {
          transition: transform 0.5s ease, color 0.5s ease;
        }

        .highlighted {
          transition: color 0.3s ease, transform 0.3s ease;
          display: inline-block;
          cursor: pointer;
        }

        .highlighted:hover {
          color: #c2410c;
          transform: scale(1.1) rotate(-2deg);
        }
      `}</style>
    </>
  );
};

export default Hero;
