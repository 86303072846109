import React, { useState, useEffect } from 'react';
import { analytics, logEvent } from './Firebase';
import baseBall from '../../assets/game_icon/1.png';
import theFaith from '../../assets/game_icon/2.png';
import hopball3d from '../../assets/game_icon/3.png';
import ballHit2 from '../../assets/game_icon/4.png';
import loopables from '../../assets/game_icon/5.png';
import ludo3d from '../../assets/game_icon/6.png';
import gunmaster3d from '../../assets/game_icon/7.png';
import agent3D from '../../assets/game_icon/8.png';
import spades from '../../assets/game_icon/9.png';

// Play Store links for each game
const gameLinks = {
  spades: 'https://play.google.com/store/apps/details?id=com.js.spades',
  homeRunMania: 'https://play.google.com/store/apps/details?id=com.example.homerunmania',
  theFaith: 'https://play.google.com/store/apps/details?id=com.karuwa.thefaith',
  ballHit2: 'https://play.google.com/store/apps/details?id=com.karuwa.ballhit',
  loopables: 'https://play.google.com/store/apps/details?id=com.karuwa.loopables',
  hopBall: 'https://play.google.com/store/apps/details?id=com.karuwa.hopball3d',
  gunmaster3d: 'https://play.google.com/store/apps/details?id=com.ug.gunmaster3d',
  ludo: 'https://play.google.com/store/apps/details?id=com.karuwaapps.ludo',
  agent3D: 'https://play.google.com/store/apps/details?id=com.karuwa.agent3d',
  baseBall: 'https://play.google.com/store/apps/details?id=com.ug.baseball',
  spades: 'https://play.google.com/store/apps/details?id=com.js.spades',
};

// Details for each game including name, image, and link
const gameDetails = [
  { name: 'Spades Classic', imgSrc: spades, link: gameLinks.spades },
  { name: 'Ludo 3D', imgSrc: ludo3d, link: gameLinks.ludo },
  { name: 'Home Run Mania', imgSrc: baseBall, link: gameLinks.baseBall },
  { name: 'Ball Hit 2', imgSrc: ballHit2, link: gameLinks.ballHit2 },
  { name: 'Loopables', imgSrc: loopables, link: gameLinks.loopables },
  { name: 'The Faith', imgSrc: theFaith, link: gameLinks.theFaith },
  { name: 'Hop Ball 3D', imgSrc: hopball3d, link: gameLinks.hopBall },
  { name: 'Gun Master 3D', imgSrc: gunmaster3d, link: gameLinks.gunmaster3d },
  { name: 'Agent 3D', imgSrc: agent3D, link: gameLinks.agent3D },
];

// Detect if the user is on a mobile device
const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

// Event handlers for hover and click events
const handleGameHover = (gameName) => {
  if (!isMobile()) {
    logEvent(analytics, 'game_hover', { game_name: gameName });
  }
};

const handleGameClick = (gameName) => {
  logEvent(analytics, 'game_click', { game_name: gameName });
};

// Popup component to display all games
const GamePopup = ({ isOpen, onClose, games }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 popup-overlay"
      onClick={handleOutsideClick}
    >
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[80%] h-[80%] overflow-y-auto relative shadow-lg">
        <h3 className="text-3xl font-bold mb-6 text-center text-gray-800">All Games</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 h-auto pr-2">
          {games.map((game, index) => (
            <a
              key={index}
              href={game.link}
              target="_blank"
              rel="noopener noreferrer"
              className="relative group overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300"
              onMouseEnter={() => handleGameHover(game.name)}
              onClick={() => handleGameClick(game.name)}
            >
              <div className="relative w-full pt-[75%] bg-gray-100 flex items-center justify-center rounded-lg overflow-hidden border border-gray-200 group-hover:border-blue-500 transition-colors duration-300">
                <img
                  src={game.imgSrc}
                  alt={game.name}
                  className="absolute inset-0 object-cover h-full w-full transition-transform duration-300 transform group-hover:scale-110"
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 p-2 text-center text-white bg-gradient-to-t from-black via-transparent to-transparent bg-opacity-80 rounded-b-lg">
                <p className="text-sm font-semibold drop-shadow-lg">{game.name}</p>
              </div>
            </a>
          ))}
        </div>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-red-600 text-white p-2 rounded-full font-semibold shadow-lg transform hover:scale-110 transition-transform"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};

// Main component
const Games = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  return (
    <>
      <style jsx>{`
        @keyframes slideIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes glow {
          0% {
            box-shadow: 0 0 10px rgba(255, 140, 0, 0.5);
          }
          50% {
            box-shadow: 0 0 20px rgba(255, 140, 0, 1);
          }
          100% {
            box-shadow: 0 0 10px rgba(255, 140, 0, 0.5);
          }
        }

        .hover\\:animate-glow:hover {
          animation: glow 1.5s infinite;
        }
      `}</style>

      <section className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300 py-20 px-10 relative">
        {/* Subtle background noise */}
        <div className="absolute inset-0 bg-noise bg-opacity-10 pointer-events-none"></div>
        <div className="container mx-auto">
          {/* Enhanced title visibility */}
          <h2 className="text-5xl font-extrabold text-center mb-12 text-gray-900 drop-shadow-lg tracking-wide">
            Featured Games
          </h2>
          {/* Grid layout with game cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {gameDetails.map((game, index) => (
              <a
                key={index}
                href={game.link}
                target="_blank"
                rel="noopener noreferrer"
                className="relative group overflow-hidden rounded-lg shadow-lg transform transition-all duration-500 hover:scale-105"
                onMouseEnter={() => handleGameHover(game.name)}
                onClick={() => handleGameClick(game.name)}
                style={{ animation: 'slideIn 0.5s ease forwards', animationDelay: `${index * 0.1}s` }}
              >
                <div className="relative w-full pt-[100%] bg-gray-200 flex items-center justify-center rounded-lg overflow-hidden">
                  <img
                    src={game.imgSrc}
                    alt={game.name}
                    className="absolute inset-0 object-contain h-full w-full transition-transform duration-500 transform group-hover:scale-110"
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 p-4 text-center text-white bg-black bg-opacity-60 rounded-b-lg">
                  <p className="text-lg font-semibold drop-shadow-lg">{game.name}</p>
                </div>
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center space-x-4 py-10"> {/* Adjusted space */}
            <button
              onClick={openPopup}
              className="relative bg-gradient-to-r from-orange-500 to-orange-700 text-white px-8 py-3 rounded-full font-semibold shadow-lg transition transform hover:scale-105 hover:shadow-xl overflow-hidden hover:animate-glow"
            >
              <span className="relative z-10">Explore All Games</span>
              <span className="shine-effect"></span>
            </button>
          </div>
        </div>
        {/* Popup for displaying all games */}
        <GamePopup isOpen={isPopupOpen} onClose={closePopup} games={gameDetails} />
      </section>
    </>
  );
};

export default Games;
