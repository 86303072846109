import React, { useState } from 'react';
import { analytics, logEvent } from './Firebase';
import { FaUser, FaGamepad, FaCoins, FaGlobe, FaPercent, FaDownload, FaTrashAlt, FaInfoCircle } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Plans = () => {
  const [mau, setMau] = useState('');
  const [category, setCategory] = useState('standard');
  const [revenue, setRevenue] = useState(null);
  const [monthlyRevenues, setMonthlyRevenues] = useState([]);
  const [t1Percent, setT1Percent] = useState('');
  const [t2Percent, setT2Percent] = useState('');
  const [t3Percent, setT3Percent] = useState('');
  const [conversionRate, setConversionRate] = useState('');
  const [installsLast30, setInstallsLast30] = useState('');
  const [uninstallsLast30, setUninstallsLast30] = useState('');

  const handlePlanClick = (planType) => {
    logEvent(analytics, 'plan_click', { plan_type: planType });
  };

  const normalizeValue = (value, defaultValue = 0) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) || parsedValue < 0 ? defaultValue : parsedValue;
  };

  const calculateCountryMultiplier = (t1, t2, t3) => {
    const t1Multiplier = t1 * 1.5;
    const t2Multiplier = t2 * 1.2;
    const t3Multiplier = t3 * 1.0;
    return (t1Multiplier + t2Multiplier + t3Multiplier) / 100 || 1;
  };

  const calculateCategoryMultiplier = (category) => {
    const categoryMultipliers = {
      standard: 1,
      premium: 1.3,
      basic: 0.7,
      casual: 1.0,
      strategy: 1.5,
      rpg: 1.4,
    };
    return categoryMultipliers[category] || 1;
  };

  const handleCalculateRevenue = () => {
    const mauValue = normalizeValue(mau);
    const t1 = normalizeValue(t1Percent);
    const t2 = normalizeValue(t2Percent);
    const t3 = normalizeValue(t3Percent);
    const convRate = normalizeValue(conversionRate, 1); // Default to 1% if not provided
    const installs = normalizeValue(installsLast30);
    const uninstalls = normalizeValue(uninstallsLast30);

    if (mauValue <= 0) {
      alert('Please enter a valid MAU number.');
      return;
    }

    if (t1 + t2 + t3 > 100) {
      alert('The sum of T1, T2, and T3 percentages cannot exceed 100%.');
      return;
    }

    const countryMultiplier = calculateCountryMultiplier(t1, t2, t3);
    const categoryMultiplier = calculateCategoryMultiplier(category);
    const conversionFactor = convRate / 100;
    const baseRevenuePerUser = 2.99; // Assuming the base revenue per user is $2.99

    // Calculate initial monthly revenue
    const initialRevenue = mauValue * conversionFactor * categoryMultiplier * countryMultiplier * baseRevenuePerUser;
    
    let cumulativeRevenue = 0;
    const monthlyRevenuesArray = Array.from({ length: 12 }, (_, i) => {
      const monthNumber = i + 1;
      
      // Calculate MAU change
      const monthlyMauIncrease = installs > 0 ? installs * monthNumber : mauValue * 0.1;
      const monthlyMau = Math.max(0, mauValue + monthlyMauIncrease - uninstalls * monthNumber); // Ensure non-negative MAU

      // Progressive revenue adjustment
      let adjustmentFactor = 1;
      if (monthNumber > 3) {
        adjustmentFactor -= 0.1 * (monthNumber - 3); // Increase reduction with each month after 3rd
      }
      adjustmentFactor = Math.max(adjustmentFactor, 0.5); // Cap the reduction to prevent too drastic drops

      // Calculate monthly revenue with adjustment
      const monthlyRevenue = monthlyMau * conversionFactor * categoryMultiplier * countryMultiplier * baseRevenuePerUser * adjustmentFactor;

      cumulativeRevenue += monthlyRevenue;
      
      return {
        month: `Month ${monthNumber}`,
        revenue: parseFloat(monthlyRevenue.toFixed(2)),
        cumulativeRevenue: parseFloat(cumulativeRevenue.toFixed(2)),
      };
    });

    setRevenue(cumulativeRevenue.toFixed(2)); // Set the revenue to the cumulative revenue after 12 months
    setMonthlyRevenues(monthlyRevenuesArray);
  };

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString();
  };

  return (
    <section className="bg-gradient-to-b from-gray-100 to-gray-300 py-20 px-10 relative">
      <div className="container mx-auto px-6">
      <h2 className="text-4xl text-center mb-12 text-blue-800">
  Let's see your game's <span className="font-bold">revenue</span> potential
</h2>


        <div className="bg-white p-8 rounded-2xl shadow-2xl text-center mb-8 transition-transform transform hover:scale-105">
          <h3 className="text-2xl font-bold mb-4 text-gray-800">Revenue Calculator</h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              icon={FaUser}
              label="Monthly Active Users (MAU)"
              value={mau}
              onChange={(e) => setMau(e.target.value)}
              placeholder="e.g., 10000"
              tooltip="Enter the number of active users per month."
            />

            <div className="flex flex-col items-start">
              <label className="flex items-center text-gray-700 mb-1">
                <FaGlobe className="text-gray-500 mr-2" /> T1, T2, T3 Country Users (%) <FaInfoCircle className="ml-1 text-blue-400 cursor-pointer" title="Enter the percentage of users from T1, T2, and T3 countries." />
              </label>
              <div className="flex space-x-2">
                <input
                  type="number"
                  value={t1Percent}
                  onChange={(e) => setT1Percent(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="T1"
                />
                <input
                  type="number"
                  value={t2Percent}
                  onChange={(e) => setT2Percent(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="T2"
                />
                <input
                  type="number"
                  value={t3Percent}
                  onChange={(e) => setT3Percent(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="T3"
                />
              </div>
            </div>

            <InputField
              icon={FaPercent}
              label="MAU to Conversion Rate (%)"
              value={conversionRate}
              onChange={(e) => setConversionRate(e.target.value)}
              placeholder="e.g., 5"
              tooltip="Enter the conversion rate from MAU to paying users."
            />

            <div className="flex flex-col items-start">
              <label className="flex items-center text-gray-700 mb-1">
                <FaDownload className="text-gray-500 mr-2" /> Installs & Uninstalls (Last 30 Days) <FaInfoCircle className="ml-1 text-blue-400 cursor-pointer" title="Enter the number of installs and uninstalls in the last 30 days." />
              </label>
              <div className="flex space-x-2">
                <input
                  type="number"
                  value={installsLast30}
                  onChange={(e) => setInstallsLast30(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Installs"
                />
                <input
                  type="number"
                  value={uninstallsLast30}
                  onChange={(e) => setUninstallsLast30(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Uninstalls"
                />
              </div>
            </div>

            <SelectField
              icon={FaGamepad}
              label="Game Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              options={[
                { value: 'standard', label: 'Standard' },
                { value: 'premium', label: 'Premium' },
                { value: 'basic', label: 'Basic' },
                { value: 'casual', label: 'Casual' },
                { value: 'strategy', label: 'Strategy' },
                { value: 'rpg', label: 'RPG' },
              ]}
              tooltip="Select the game category to calculate revenue accordingly."
            />
          </div>

          <button
            onClick={handleCalculateRevenue}
            className="bg-green-500 hover:bg-green-600 text-white p-3 rounded-full mt-6 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg"
          >
            Calculate Potential Revenue Increase
          </button>

          {revenue !== null && (
            <div className="mt-6 p-6 bg-green-50 rounded-lg shadow-inner">
              <h4 className="text-xl font-bold text-gray-700">Estimated Total Revenue After 12 Months:</h4>
              <p className="text-3xl font-bold text-green-600"><FaCoins className="inline-block mr-2" />${formatNumberWithCommas(parseFloat(revenue))}</p>
            </div>
          )}
        </div>

        {monthlyRevenues.length > 0 ? (
          <div className="bg-white p-8 rounded-2xl shadow-2xl text-center mb-8">
            <h3 className="text-2xl font-bold mb-4 text-gray-800">Projected Revenue Over 1 Year</h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={monthlyRevenues}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis tickFormatter={formatNumberWithCommas} />
                <Tooltip formatter={(value) => formatNumberWithCommas(parseFloat(value))} />
                <Legend payload={[
                  { value: 'Monthly Revenue', type: 'line', color: '#8884d8' },
                  { value: 'Cumulative Revenue', type: 'line', color: '#82ca9d' }
                ]} />
                <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="cumulativeRevenue" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <p className="text-center text-gray-600">No revenue data available. Please calculate the revenue first.</p>
        )}
      </div>
    </section>
  );
};

const InputField = ({ icon: Icon, label, value, onChange, placeholder, tooltip }) => (
  <div className="flex flex-col items-start">
    <label className="flex items-center text-gray-700 mb-1">
      <Icon className="text-gray-500 mr-2" /> {label} <FaInfoCircle className="ml-1 text-blue-400 cursor-pointer" title={tooltip} />
    </label>
    <input
      type="number"
      value={value}
      onChange={onChange}
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      placeholder={placeholder}
    />
  </div>
);

const SelectField = ({ icon: Icon, label, value, onChange, options, tooltip }) => (
  <div className="flex flex-col items-start">
    <label className="flex items-center text-gray-700 mb-1">
      <Icon className="text-gray-500 mr-2" /> {label} <FaInfoCircle className="ml-1 text-blue-400 cursor-pointer" title={tooltip} />
    </label>
    <select
      value={value}
      onChange={onChange}
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
);

export default Plans;
