import React from 'react';
import { FaPlug, FaChartLine, FaDollarSign, FaEnvelope } from 'react-icons/fa';
import { analytics, logEvent } from './Firebase';

const Features = () => {
  const features = [
    { name: 'SDK-less Integration', description: 'Integrate seamlessly without complex setups.', icon: <FaPlug /> },
    { name: 'Lifetime Revenue Share', description: 'Earn 50% conversion amount for a lifetime, even if players delete your games.', icon: <FaDollarSign /> },
    { name: 'Discovery Channel', description: 'Reach 15,000 users in our email list.', icon: <FaEnvelope /> },
    { name: 'Advanced Analytics', description: 'Detailed insights into user behavior and game performance.', icon: <FaChartLine /> },
  ];

  const handleFeatureClick = (featureName) => {
    logEvent(analytics, 'feature_click', { feature: featureName });
  };

  return (
    <section className="bg-gradient-to-b from-gray-100 to-gray-300 py-20 px-10 relative">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">Why integrate Play Unite in your games</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {features.map((feature, index) => (
            <article
              key={index}
              className="flex flex-col items-center text-center p-6 bg-gray-100 rounded-lg shadow-lg transition-transform transform hover:scale-105"
              onClick={() => handleFeatureClick(feature.name)}
              role="button"
              aria-label={`Learn more about ${feature.name}`}
            >
              <div className="text-4xl text-blue-500 mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{feature.name}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
