import React, { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { analytics, logEvent } from './Firebase'; // Import Firebase Analytics

const Plans = () => {
  const handleSubscriptionClick = (planType) => {
    logEvent(analytics, 'subscription_click', { plan_type: planType });
    window.open("https://play.google.com/store/apps/details?id=com.ug.playunite&referrer=play-unite.com", "_blank");
  };

  const handleDownloadClick = () => {
    logEvent(analytics, 'download_click');
  };

  useEffect(() => {
    // Trigger animations on mount
    const cards = document.querySelectorAll('.plan-card');
    cards.forEach((card, index) => {
      card.style.animation = `fadeInUp 0.5s ease ${index * 0.2}s forwards`;
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-200 flex flex-col items-center justify-center font-sans text-gray-900 py-12 px-4">
      <div className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-6">Unleash Your Inner Gamer</h1>
        <p className="text-lg text-gray-700 mb-8">Your subscription includes:</p>
        <ul className="list-none space-y-4 text-lg max-w-lg mx-auto">
          {[
            "Top-tier games at your fingertips.",
            "Ad-free, uninterrupted gameplay.",
            "Unlimited access, play anytime.",
            "Cancel anytime, hassle-free.",
          ].map((benefit, index) => (
            <li key={index} className="flex items-center">
              <FaCheckCircle className="text-green-500 text-2xl mr-3" />
              <span>{benefit}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="bg-white rounded-3xl shadow-2xl p-8 md:p-12 w-full max-w-4xl mb-12">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-semibold text-gray-800">Pick Your Power-Up</h2>
        </div>
        <div className="flex flex-col md:flex-row justify-around items-center space-y-6 md:space-y-0">
          <div
            className="plan-card bg-gradient-to-r from-orange-100 to-orange-200 p-8 rounded-xl shadow-lg flex-1 text-center transform hover:scale-105 transition-transform cursor-pointer floating"
            style={{ minHeight: '220px' }}
            onClick={() => handleSubscriptionClick('Monthly')}
          >
            <h3 className="text-3xl font-bold text-orange-700 mb-4">Monthly Power Boost</h3>
            <p className="text-xl text-gray-800 mb-6">$7.99/month</p>
            <p className="text-sm text-gray-600">All the perks, none of the pressure. Cancel anytime.</p>
          </div>
          <div
            className="plan-card bg-gradient-to-r from-purple-100 to-purple-200 p-8 rounded-xl shadow-lg flex-1 text-center transform hover:scale-105 transition-transform cursor-pointer relative overflow-hidden floating"
            style={{ minHeight: '220px' }}
            onClick={() => handleSubscriptionClick('Annual')}
          >
            <h3 className="text-3xl font-bold text-purple-700 mb-4">Annual Power-Up</h3>
            <p className="text-xl text-gray-800 mb-6">$69.99/year</p>
            <p className="text-sm text-gray-600">A full year of epic gaming at the best value. Cancel anytime.</p>
            <span className="shine-effect"></span>
          </div>
        </div>
      </div>

      <div className="text-center">
        <p className="text-lg text-gray-800 mb-6">Ready to power up? Download Play Unite and get started!</p>
        <div className="flex justify-center space-x-4">
          <a
            href="https://play.google.com/store/apps/details?id=com.ug.playunite&referrer=play-unite.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDownloadClick}
            className="focus:outline-none focus:ring-2 focus:ring-orange-500 rounded"
          >
            <img
              src="https://cdn.prod.website-files.com/65e5387a432941b8434eddf9/65e911f042793497b65ebbdc_en_badge_web_generic-p-500.png"
              alt="Get it on Google Play"
              className="w-36 transition-transform transform hover:scale-105 hover:animate-ripple"
            />
          </a>
        </div>
      </div>

      <style jsx>{`
        .shine-effect {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            120deg,
            transparent,
            rgba(255, 255, 255, 0.7) 40%,
            transparent 60%
          );
          opacity: 0.9;
          transform: translateX(-150%);
          animation: shine 2s infinite ease-in-out;
        }

        @keyframes shine {
          0% {
            transform: translateX(-150%);
          }
          50% {
            transform: translateX(150%);
          }
          100% {
            transform: translateX(150%);
          }
        }

        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes ripple {
          0% {
            transform: scale(1);
            opacity: 0.9;
          }
          100% {
            transform: scale(1.5);
            opacity: 0;
          }
        }

        .hover\\:animate-ripple:hover::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 50%;
          animation: ripple 0.6s linear;
        }

        .floating {
          animation: float 3s ease-in-out infinite;
        }

        @keyframes float {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(-10px);
          }
          100% {
            transform: translatey(0px);
          }
        }

        .plan-card:hover {
          box-shadow: 0 0 15px rgba(255, 165, 0, 0.5); /* Glow effect on hover */
        }

        .plan-card:hover h3,
        .plan-card:hover p {
          transform: translateX(10px); /* Text reveal animation on hover */
          transition: transform 0.3s ease;
        }

        .plan-card:hover {
          transform: rotateY(10deg); /* Tilt effect on hover */
          transition: transform 0.3s ease;
        }
      `}</style>
    </div>
  );
};

export default Plans;
