import React, { useState } from 'react';
import { analytics, logEvent } from './Firebase'; // Adjust the import path if necessary
import { Link } from 'react-router-dom';

// Reusable Modal Component
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      onClick={(e) => {
        if (e.target.classList.contains('bg-black')) onClose();
      }}
    >
      <div className="bg-white p-8 rounded-lg max-w-lg w-full max-h-screen overflow-y-auto shadow-lg">
        <h2 id="modal-title" className="text-2xl font-semibold mb-4">
          {title}
        </h2>
        <div className="text-sm text-gray-600">{children}</div>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-500"
          aria-label="Close Modal"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);

    // Log event to Firebase Analytics when a modal is opened
    logEvent(analytics, 'modal_open', {
      content_type: content === 'privacy' ? 'Privacy Policy' : 'Terms of Use',
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent('');

    // Log event to Firebase Analytics when a modal is closed
    logEvent(analytics, 'modal_close', {
      content_type: modalContent === 'privacy' ? 'Privacy Policy' : 'Terms of Use',
    });
  };

  return (
    <>
      <footer className="bg-orange-400 py-8 text-center text-white">
        <div className="container mx-auto">
          <div className="flex justify-center space-x-6 mb-4">
            <button
              onClick={() => openModal('privacy')}
              className="text-lg font-light hover:underline focus:outline-none focus:underline"
            >
              Privacy Policy
            </button>
            <button
              onClick={() => openModal('terms')}
              className="text-lg font-light hover:underline focus:outline-none focus:underline"
            >
              Terms of Use
            </button>
            <Link to="/developers" className="text-lg font-light hover:underline focus:outline-none focus:underline">
              Developers
            </Link>
          </div>
          <p className="mt-4 text-sm">Crafted with ❤️ in mountains of Nepal 🇳🇵</p>
        </div>
      </footer>

      <Modal isOpen={showModal} onClose={closeModal} title={modalContent === 'privacy' ? 'Privacy Policy' : 'Terms of Use'}>
        {modalContent === 'privacy' ? (
          <>
            <p><strong>Unicorn Games ("we", "us", or "our")</strong> operates all the mobile applications developed by Unicorn Games LLC (hereinafter referred to as the "Service").</p>
            <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
            <p><strong>Consent and Data Usage:</strong> By using the Service, you agree to the collection and use of information in accordance with this policy. The personal information we collect is used solely for providing and improving the Service. We ensure that your information is not used for tracking purposes as defined under Apple’s guidelines, nor is it shared with third parties for advertising, marketing, or tracking purposes.</p>
            <p><strong>Information Collection and Use:</strong> For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, such as your email, name, and phone number. The information we collect will be used to contact or identify you and is retained in accordance with this Privacy Policy. We do not engage in any form of cross-app tracking or advertising using your personal information.</p>
            <p><strong>Personal Data Deletion Request:</strong> If you wish to request the deletion of your Personal Information, please complete the form available at: Personal Data Deletion Request Form. Upon receiving your request, we will remove your information from our servers within seven working days and confirm once the process has been completed.</p>
            <p><strong>Third-Party Services:</strong> The app may use services provided by third parties whose privacy practices may differ from ours. Our use of third-party services is carefully selected to ensure they do not involve sharing data that allows them to track users across apps or websites. We recommend that you read the privacy policies of these third-party service providers.</p>
            <p><strong>Security:</strong> The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. We strive to use commercially acceptable means to protect your Personal Information but cannot guarantee its absolute security.</p>
            <p><strong>Children’s Privacy:</strong> Our Service does not cater to anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that we have collected Personal Information from children without verification of parental consent, we take steps to remove that information from our servers.</p>
            <p><strong>Changes to This Privacy Policy:</strong> Our Privacy Policy may change from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            <p><strong>Contact Us:</strong> If you have any questions or suggestions about our Privacy Policy, please do not hesitate to contact us at g@unicorn.games.</p>
            <p>This policy is effective as of March 11th, 2024.</p>
          </>
        ) : (
          <>
            <p><strong>End-User License Agreement ("Agreement")</strong></p>
            <p>Please read this End-User License Agreement ("Agreement") carefully before clicking the "I Agree" button, downloading, or using any applications ("Apps") operated by Unicorn Games LLC ("us", "we", or "our").</p>
            <p>By clicking the "I Agree" button, downloading, or using our Apps, you are agreeing to be bound by the terms and conditions of this Agreement.</p>
            <p><strong>1. Acceptance of Terms:</strong> By downloading or using the Apps provided by Unicorn Games LLC ("Company," "we," "us," or "our"), you agree to be bound by these terms of use ("Terms"). If you do not agree to these Terms, do not use our Apps.</p>
            <p><strong>2. License Grant:</strong> We grant you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the Apps for your personal, non-commercial purposes strictly in accordance with these Terms.</p>
            <p><strong>3. Restrictions:</strong> You agree not to, and will not permit others to:</p>
            <ul className="list-disc list-inside">
              <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the Apps.</li>
              <li>Modify, make derivative works of, disassemble, decrypt, reverse compile, or reverse engineer any part of the Apps.</li>
              <li>Remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the Apps.</li>
            </ul>
            <p><strong>4. Intellectual Property Rights:</strong> All rights not expressly granted to you in these Terms are reserved and retained by the Company or its licensors, suppliers, publishers, rights holders, or other content providers.</p>
            <p><strong>5. Your Content:</strong> You retain all rights and ownership of your content. We do not claim any ownership rights to your content.</p>
            <p><strong>6. In-App Purchases:</strong> Our Apps may include in-app purchases that can be bought with real money. You are responsible for managing your in-app purchases.</p>
            <p><strong>7. Subscriptions:</strong> Some parts of the service are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis (billing cycle). At the end of each billing cycle, your subscription will automatically renew under the exact same conditions unless you cancel it or Unicorn Games LLC cancels it.</p>
            <p><strong>8. No Warranties:</strong> The Apps are provided "as is," without any warranty of any kind.</p>
            <p><strong>9. Limitation of Liability:</strong> In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>
            <p><strong>10. Amendments to this Agreement:</strong> The Company reserves the right, at its sole discretion, to modify or replace these Terms at any time.</p>
            <p><strong>11. Governing Law:</strong> The laws of Nebraska, United States, excluding its conflicts of law rules, shall govern this Agreement and your use of the Apps.</p>
            <p><strong>12. Contact Information:</strong> If you have any questions about these Terms, please contact us.</p>
          </>
        )}
      </Modal>
    </>
  );
};

export default Footer;
