// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";  // Import logEvent function

const firebaseConfig = {
  apiKey: "AIzaSyCc5wYJbFsVSzDxEoUa4mcMOnlBzMDsrAU",
  authDomain: "play-unite.firebaseapp.com",
  projectId: "play-unite",
  storageBucket: "play-unite.appspot.com",
  messagingSenderId: "227578465979",
  appId: "1:227578465979:web:6df9b3eef4f1834002239e",
  measurementId: "G-X9L1QZETVX"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, logEvent };  // Export logEvent
