import React, { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import { analytics, logEvent } from './pages/gamers/Firebase';  // Import logEvent
import Hero from './pages/gamers/Hero';
import Games from './pages/gamers/Games';
import Footer from './pages/gamers/Footer';
import Plans from './pages/gamers/Plans';
import DeveloperLandingPage from './pages/developer/LandingPage'; // Import the LandingPage for developers

function App() {
  const plansRef = useRef(null);
  const gamesRef = useRef(null);

  const scrollToPlans = () => {
    if (plansRef.current) {
      plansRef.current.scrollIntoView({ behavior: 'smooth' });
      logEvent(analytics, 'scroll_to_plans');  // Use logEvent function
    }
  };

  const scrollToGames = () => {
    if (gamesRef.current) {
      gamesRef.current.scrollIntoView({ behavior: 'smooth' });
      logEvent(analytics, 'scroll_to_games');  // Use logEvent function
    }
  };

  useEffect(() => {
    logEvent(analytics, 'app_loaded');  // Use logEvent function
  }, []);

  // Trigger analytics data to the Cloud Function
  const triggerAnalytics = async (queryParams) => {
    const response = await fetch('/puAnalytics?' + new URLSearchParams(queryParams), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    console.log(data);
    return data;
  };

  // Function to fetch necessary data from an API
  const fetchDataFromApi = async () => {
    try {
      const response = await fetch('/yourApiEndpoint'); // Replace with your actual API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;  // Return the fetched data to be used later
    } catch (error) {
      console.error('Failed to fetch data:', error);
      return null;  // Return null or handle the error as needed
    }
  };

  // Memoize the handleAnalyticsEvent function
  const handleAnalyticsEvent = useCallback(async () => {
    const apiData = await fetchDataFromApi();  // Fetch data from your API
    if (apiData) {
      const queryParams = {
        gameID: apiData.gameID,  // Assuming API returns gameID
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        idfa: apiData.idfa || 'some-idfa-value',  // Assuming API returns idfa or use default
      };
      triggerAnalytics(queryParams);  // Pass the fetched data to triggerAnalytics
    }
  }, []);

  // Trigger the analytics event on component mount
  useEffect(() => {
    handleAnalyticsEvent();  // Trigger on component load with fetched API data
  }, [handleAnalyticsEvent]);

  return (
    <Router>
      <div className="min-h-screen bg-gray-50 font-sans text-gray-900">
        <Routes>
          {/* Main Landing Page for Gamers */}
          <Route 
            path="/" 
            element={
              <>
                <Hero scrollToPlans={scrollToPlans} scrollToGames={scrollToGames} />
                <div ref={gamesRef}>
                  <Games />
                </div>
                <div ref={plansRef}>
                  <Plans />
                </div>
                <Footer />
              </>
            } 
          />

          {/* Developer Landing Page */}
          <Route path="/developers" element={<DeveloperLandingPage />} />
          
          {/* Add other routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
